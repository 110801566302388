import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "copyLinkButton",
    "checkboxShowTeaser",
    "inputForShareUrl",
    "checkboxShareToWeb",
    "checkboxMinimalLayout",
  ];

  updateButtonProps() {
    this.copyLinkButtonTarget.innerHTML =
      this.copyLinkButtonTarget.dataset.linkCopiedText;
    if (
      !this.copyLinkButtonTarget.classList.contains(
        "share-to-web-url__btn-copied",
      )
    ) {
      this.copyLinkButtonTarget.classList.add("share-to-web-url__btn-copied");
    }
  }

  toggleFieldDescription(field, description) {
    field.innerHTML = description;
  }

  setInputValueForShareUrl(url) {
    this.inputForShareUrlTarget.value = url;
  }

  toggleCheckboxForMinimalLayout(minimalLayout) {
    if (this.hasCheckboxMinimalLayoutTarget) {
      this.checkboxMinimalLayoutTarget.checked = minimalLayout;
    }
  }

  uncheckCheckboxForShowPostTeaser() {
    this.checkboxShowTeaserTarget.checked = false;
  }

  success(event) {
    const eventDetail = event.detail[0];
    const shareToWebOnDescription =
      this.checkboxShareToWebTarget.dataset.shareToWebOnDescription;
    const shareToWebOffDescription =
      this.checkboxShareToWebTarget.dataset.shareToWebOffDescription;
    const sharePostLink = eventDetail.share_post_link;
    const minimalLayout = eventDetail.minimal_layout;
    const postId = eventDetail.post_id;
    const sharePostEnabled = eventDetail.share_post_enabled;
    const fieldShareToWeb = document.querySelector(
      `.share-to-web-${postId} .field-description`,
    );

    if (sharePostEnabled) {
      this.toggleFieldDescription(fieldShareToWeb, shareToWebOnDescription);
      this.setInputValueForShareUrl(sharePostLink);
      this.toggleCheckboxForMinimalLayout(minimalLayout);
    } else {
      this.uncheckCheckboxForShowPostTeaser();
      this.toggleFieldDescription(fieldShareToWeb, shareToWebOffDescription);
    }
  }
}
