import { ResizeObserver } from "@juggle/resize-observer";
import linkifyHtml from "linkifyjs/html";
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.openDownloadLinksInNewWindow();
    this.enableTruncation();
    this.makeAllLinksOpenInNewWindow();
    this.convertNonLinksToLinks();
    this.addNoDownloadAttrToVideo();
    this.transformDataBackgroundImage();
  }

  convertNonLinksToLinks() {
    const textInside = this.element.getElementsByClassName("post__inside")[0];
    let bodyText = "";

    if (textInside !== undefined) {
      bodyText = textInside.innerHTML;

      bodyText = linkifyHtml(bodyText, {
        defaultProtocol: "https",
        target: {
          url: "_blank",
        },
        ignoreTags: [
          "a",
          "style",
          "script",
          "img",
          "iframe",
          "action-text-attachment",
          "video",
          "audio",
        ],
        validate: {
          url: value => /^[^A-Z]/.test(value),
        },
      });
      textInside.innerHTML = bodyText;
    }
  }

  addNoDownloadAttrToVideo() {
    const videoObjects = this.element.getElementsByTagName("video");

    if (videoObjects.length > 0) {
      for (var i = 0; i < videoObjects.length; i++) {
        videoObjects[i].setAttribute("controlsList", "nodownload");
      }
    }
  }

  enableTruncation() {
    /*
     * Since the pseudo class :truncated is sadly not a thing, if your truncated box is responsive or the text in the box is of arbitrary size, the following code adds or removes a "truncated" class to simulate the feature.
     *
     * Warning: Doesn't work in all browsers due to the use of ResizeObserver, use something like the window's resize event if you need it to be more cross browser.
     */
    const bodyText = this.element.getElementsByClassName("post__inside")[0];
    const expandButton = this.element.getElementsByClassName("post__expand")[0];

    if (expandButton) {
      const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          const shouldAdd =
            entry.target.scrollHeight > entry.contentRect.height;

          entry.target.classList[shouldAdd ? "add" : "remove"]("truncated");
          expandButton.innerHTML = shouldAdd
            ? expandButton.dataset.showMore
            : expandButton.dataset.showLess;

          // Safari will return height == 0 because it doesn't fully support resizeObserver
          // So we can remove truncated logic in this instance
          if (entry.contentRect.height === 0 || !entry.contentRect.height)
            bodyText.style["-webkit-" + "line-clamp"] = "unset";
        }
      });

      observer.observe(bodyText);
    }
  }

  toggleExpandClass(ev) {
    const shouldAdd = !ev.target.classList.contains("expanded");
    ev.target.classList[shouldAdd ? "add" : "remove"]("expanded");
    ev.target.innerHTML = shouldAdd
      ? ev.target.dataset.showMore
      : ev.target.dataset.showLess;
  }

  makeAllLinksOpenInNewWindow() {
    const links = this.element.getElementsByTagName("a");

    if (links.length > 0) {
      for (var i = 0; i < links.length; i++) {
        const el = links[i];

        if (
          el.tagName === "A" &&
          !el.isContentEditable &&
          el.host !== window.location.host
        ) {
          el.setAttribute("target", "_blank");
        }
      }
    }
  }

  openDownloadLinksInNewWindow() {
    const downloadLink =
      this.element.getElementsByClassName("attachment__link");

    if (downloadLink.length > 0) {
      for (var i = 0; i < downloadLink.length; i++) {
        downloadLink[i].setAttribute("target", "_blank");
      }
    }
  }

  transformDataBackgroundImage() {
    this.element.querySelectorAll("[data-background-image]").forEach(e => {
      const image = document.createElement("img");
      image.src = e.dataset.backgroundImage;
      e.style.backgroundImage = `url("${e.dataset.backgroundImage}")`;
      image.onload = function () {
        const { parentElement } = e;
        parentElement.classList.add("show");
        parentElement.classList.remove("hide");
      };
    });
  }

  downloadAttachment(event) {
    event.preventDefault();
    event.stopPropagation();
    const {
      currentTarget: {
        dataset: { url, fileName, logUrl },
      },
    } = event;
    this.download(url, fileName);
    this.trackDownload(logUrl);
  }

  download(url, filename) {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.setAttribute("download", filename);
    a.click();
  }

  trackDownload(url) {
    fetch(url, { method: "POST" });
  }
}
