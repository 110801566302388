import { Controller } from "stimulus";

const psl = require("psl");

export default class extends Controller {
  static targets = ["customDomain", "customHost", "rootDomainWarning"];

  connect() {
    this.customDomainTarget.onkeyup = this.validateDomain;
  }

  validateDomain = () => {
    const value = psl.parse(this.customDomainTarget.value);
    if (psl.isValid(this.customDomainTarget.value)) {
      this.customDomainTarget.classList.remove("invalid-cname");
      this.customDomainTarget.classList.add("valid-cname");
      this.customHostTarget.classList.remove("host-placeholder");
      if (value.subdomain == null) {
        this.customHostTarget.innerText = "www";
        this.rootDomainWarningTarget.style.display = "block";
      } else {
        this.customHostTarget.innerText = value.subdomain;
        this.rootDomainWarningTarget.style.display = "none";
      }
    } else {
      this.customDomainTarget.classList.remove("valid-cname");
      this.customDomainTarget.classList.add("invalid-cname");
      this.rootDomainWarningTarget.style.display = "none";
      this.customHostTarget.classList.add("host-placeholder");
      this.customHostTarget.innerText = "my-custom-subdomain";
    }
  };
}
