import { nameToHslColor, nameToInitials } from "@circleco/ui-kit";
import { Controller } from "stimulus";

/** @deprecated please use UserImage component from @circleco/ui-kit */
export default class extends Controller {
  connect() {
    const { name } = this.element.dataset;
    // the update can be cached by turbolinks which can causes multiple initials to be visible
    // without this check
    if (!this.element.querySelector(".user__initials")) {
      const child = document.createElement("div");
      child.classList.add("user__initials", "text-white");
      child.style = `background-color: ${nameToHslColor(name)};`;
      child.innerText = nameToInitials(name);
      this.element.appendChild(child);
    }
  }
}
