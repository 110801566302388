import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["customSignUp"];

  connect() {}

  toggleCustomSignUp(event) {
    if (event.target.checked && !this.customSignUpTarget.checked) {
      this.customSignUpTarget.checked = true;

      this.customSignUpTarget.dispatchEvent(new Event("change"));
    }
  }
}
