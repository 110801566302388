import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "button"];

  keyup(e) {
    if (e.target.selectionStart > 5) {
      this.setCursorPos(e.target, 0);
      this.buttonTarget.click();
    }
  }

  setCursorPos(elem, pos) {
    if (elem.setSelectionRange) {
      elem.setSelectionRange(pos, pos);
    } else if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.collapse(true);
      range.moveEnd("character", pos);
      range.moveStart("character", pos);
      range.select();
    }
    elem.scrollLeft = 0;
  }
}
