import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const {
      element,
      element: {
        href,
        dataset: { fileName },
      },
    } = this;
    element.addEventListener("click", event => {
      event.preventDefault();
      this.download(href, fileName);
    });
  }

  download(url, filename) {
    fetch(url)
      .then(t => t.blob())
      .then(blob => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.setAttribute("download", filename);
        a.click();
      });
  }
}
