import { Controller } from "stimulus";

export default class extends Controller {
  toggle() {
    document
      .querySelectorAll(".view-space .post__reply form")
      .forEach(function (el) {
        el.style.zIndex = "-1000";
      });

    document.querySelectorAll(".view-post .post__reply").forEach(function (el) {
      el.style.zIndex = "-1000";
    });
  }

  hide() {
    document
      .querySelectorAll(".view-space .post__reply form")
      .forEach(function (el) {
        el.style.zIndex = null;
      });

    document.querySelectorAll(".view-post .post__reply").forEach(function (el) {
      el.style.zIndex = null;
    });
  }
}
