import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleable", "toggleTrigger"];

  connect() {
    this.toggleClass = this.data.get("class") || "hidden";

    if (
      window.location.hash == this.element.dataset.path ||
      this.element.dataset.open
    ) {
      this.toggle();
    }

    if (this.toggleTriggerTarget.dataset.open) {
      if (this.toggleTriggerTarget.dataset.open == "true") {
        this.toggleTriggerTarget.classList.add("open");

        this.toggleableTargets.forEach(target => {
          target.classList.remove(this.toggleClass);
        });
      } else {
        this.toggleTriggerTarget.classList.remove("open");

        this.toggleableTargets.forEach(target => {
          target.classList.add(this.toggleClass);
        });
      }
    }
  }

  toggle(event) {
    if (event) {
      event.preventDefault();
    }

    if (event && event.type == "change" && event.target.value != "true") {
      return;
    }

    history.pushState(null, null, this.element.dataset.path);

    this.toggleTriggerTarget.classList.add("hidden");
    this.toggleTriggerTarget.classList.toggle("open");

    if (this.element.dataset.inputFocus) {
      setTimeout(
        function () {
          document.getElementById(this.element.dataset.inputFocus).focus();
        }.bind(this),
        100,
      );
    }

    this.toggleableTargets.forEach(target => {
      target.classList.toggle(this.toggleClass);
    });
  }

  done() {
    this.toggleTriggerTarget.classList.show("hidden");
  }
}
