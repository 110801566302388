import debounce from "lodash.debounce";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "hidden", "results", "clear"];

  connect() {
    this.resultsTarget.hidden = true;

    this.inputTarget.setAttribute("autocomplete", "off");
    this.inputTarget.setAttribute("spellcheck", "false");

    this.mouseDown = false;

    this.onInputChange = debounce(this.onInputChange.bind(this), 300);
    this.onResultsClick = this.onResultsClick.bind(this);
    this.onResultsMouseDown = this.onResultsMouseDown.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onWindowClick = this.onWindowClick.bind(this);
    this.onKeydown = this.onKeydown.bind(this);

    this.inputTarget.addEventListener("keydown", this.onKeydown);
    this.inputTarget.addEventListener("blur", this.onInputBlur);
    this.inputTarget.addEventListener("input", this.onInputChange);
    this.resultsTarget.addEventListener("mousedown", this.onResultsMouseDown);
    this.resultsTarget.addEventListener("mouseover", this.onResultsMouseDown);
    this.resultsTarget.addEventListener("click", this.onResultsClick);
    window.addEventListener("click", this.onWindowClick);
  }

  disconnect() {
    this.inputTarget.removeEventListener("keydown", this.onKeydown);
    this.inputTarget.removeEventListener("focus", this.onInputFocus);
    this.inputTarget.removeEventListener("blur", this.onInputBlur);
    this.inputTarget.removeEventListener("input", this.onInputChange);
    this.resultsTarget.removeEventListener(
      "mousedown",
      this.onResultsMouseDown,
    );
    this.resultsTarget.removeEventListener("click", this.onResultsClick);
    window.removeEventListener("click", this.onInputBlur);
  }

  sibling(next) {
    const options = Array.from(
      this.resultsTarget.querySelectorAll('[role="option"]'),
    );
    const selected = this.resultsTarget.querySelector('[aria-selected="true"]');
    const index = options.indexOf(selected);
    const sibling = next ? options[index + 1] : options[index - 1];
    const def = next ? options[0] : options[options.length - 1];
    return sibling || def;
  }

  select(target) {
    for (const el of this.resultsTarget.querySelectorAll(
      '[aria-selected="true"]',
    )) {
      el.removeAttribute("aria-selected");
      el.classList.remove("active");
    }
    target.setAttribute("aria-selected", "true");
    target.classList.add("active");
    this.inputTarget.setAttribute("aria-activedescendant", target.id);
  }

  unselectAll() {
    var elementList = this.resultsTarget.getElementsByClassName(
      "search-results__item",
    );
    for (var i = 0; i < elementList.length; i++) {
      elementList[i].classList.remove("active");
    }
  }

  onKeydown(event) {
    switch (event.key) {
      case "Escape":
        if (!this.resultsTarget.hidden) {
          this.hideAndRemoveOptions();
          event.stopPropagation();
          event.preventDefault();
        }
        break;
      case "ArrowDown":
        {
          const item = this.sibling(true);
          this.unselectAll();
          if (item) this.select(item);
          event.preventDefault();
        }
        break;
      case "ArrowUp":
        {
          const item = this.sibling(false);
          this.unselectAll();
          if (item) this.select(item);
          event.preventDefault();
        }
        break;
      case "Tab":
        {
          const selected = this.resultsTarget.querySelector(
            '[aria-selected="true"]',
          );
          if (selected) {
            this.commit(selected);
          }
        }
        break;
      case "Enter":
        {
          event.preventDefault();
          const selected = this.resultsTarget.querySelector(
            '[aria-selected="true"]',
          );
          if (selected && !this.resultsTarget.hidden) {
            this.commit(selected);
          }
        }
        break;
    }
  }

  onInputBlur() {
    if (this.mouseDown) return;
    this.resultsTarget.hidden = true;
  }

  onWindowClick() {
    this.resultsTarget.hidden = true;
  }

  commit(selected) {
    if (selected.getAttribute("aria-disabled") === "true") return;

    selected.getElementsByTagName("a")[0].click();

    this.resultsTarget.hidden = true;
    const textValue = selected.textContent.trim();
    const value = selected.getAttribute("data-autocomplete-value") || textValue;

    this.inputTarget.focus();
    this.hideAndRemoveOptions();

    this.element.dispatchEvent(
      new CustomEvent("autocomplete.change", {
        bubbles: true,
        detail: { value: value, textValue: textValue },
      }),
    );
  }

  onResultsClick(event) {
    if (!(event.target instanceof Element)) return;
    const selected = event.target.closest('[role="option"]');
    if (selected) this.commit(selected);
  }

  onResultsMouseDown(event) {
    this.mouseDown = true;
    this.unselectAll();
    event.target.parentNode.classList.add("active");
    this.resultsTarget.addEventListener(
      "mouseup",
      () => (this.mouseDown = false),
      { once: true },
    );
  }

  clear() {
    const val = this.inputTarget.value;
    if (val && val !== "") {
      this.inputTarget.value = "";
      this.clearTarget.classList.add("hidden");
    }
  }

  onInputChange() {
    const val = this.inputTarget.value;
    const isHidden = this.clearTarget.classList.contains("hidden");

    if (this.inputTarget.getAttribute("data-name") === "v2-search") {
      this.removeAmountEl();
    }

    if (val && val !== "" && isHidden) {
      this.clearTarget.classList.remove("hidden");
    } else if (val === "") {
      this.clearTarget.classList.add("hidden");
    }

    this.element.removeAttribute("value");
    this.fetchResults();
  }

  identifyOptions() {
    let id = 0;
    for (const el of this.resultsTarget.querySelectorAll(
      '[role="option"]:not([id])',
    )) {
      el.id = `${this.resultsTarget.id}-option-${id++}`;
    }
  }

  hideAndRemoveOptions() {
    this.resultsTarget.hidden = true;
    this.resultsTarget.innerHTML = null;
  }

  removeAmountEl() {
    const amountEl =
      this.resultsTarget.parentNode.parentNode.getElementsByClassName(
        "search-results__amount",
      );
    if (amountEl.length > 0) {
      amountEl[0].remove();
    }
  }

  fetchResults() {
    const query = this.inputTarget.value.trim();
    if (!query || query.length < this.minLength) {
      this.hideAndRemoveOptions();
      return;
    }

    if (!this.src) return;

    const url = new URL(this.src, window.location.href);
    const params = new URLSearchParams(url.search.slice(1));
    params.append("q", query);
    url.search = params.toString();

    this.element.dispatchEvent(new CustomEvent("loadstart"));

    // Preloader setup
    const inputName = this.inputTarget.getAttribute("data-name");

    const parentWrap = this.element.parentElement;
    const searchBar = parentWrap.lastElementChild.firstElementChild;
    const loader = document.createElement("div");
    loader.classList.add("trix-preloader", "search-preloader");
    let searchIcon;

    if (inputName === "v1-search") {
      searchIcon = parentWrap.lastElementChild;
      searchIcon.classList.add("hidden");
    } else if (inputName === "v2-search") {
      const searchIcon = searchBar.nextSibling;
      searchIcon.classList.add("search-loading");
      this.toggleV2SearchIcon();
    }
    parentWrap.appendChild(loader);

    fetch(url.toString())
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html;

        if (inputName === "v1-search") {
          searchIcon.classList.remove("hidden");
          parentWrap.getElementsByTagName("svg")[0].classList.remove("hidden");
        } else if (inputName === "v2-search") {
          searchBar.firstElementChild.classList.remove("search-loading");

          const parseHTML = str => {
            var dom = document.createElement("div");
            dom.innerHTML = str;
            return dom;
          };

          const resultsDiv = document.createElement("div");
          resultsDiv.classList.add("search-results__amount");
          const amount = parseHTML(html).getElementsByTagName("li");
          const amountLength = amount.length;

          if (amountLength >= 1 && amount[0].innerText !== "No results") {
            this.resultsTarget.classList.add("has-results");

            if (amountLength === 1) {
              resultsDiv.innerHTML = amountLength + " Result";
            } else {
              resultsDiv.innerHTML = amountLength + " Results";
            }

            this.resultsTarget.parentNode.parentNode.appendChild(resultsDiv);
          } else if (amountLength < 1 && amount[0].innerText === "No results") {
            this.resultsTarget.classList.remove("has-results");
          }
        }

        loader.remove();

        if (inputName === "v2-search") {
          this.toggleV2SearchIcon();
        }

        const firstElement = this.resultsTarget.getElementsByTagName("li")[0];
        firstElement.classList.add("active");
        firstElement.setAttribute("aria-selected", "true");
        this.inputTarget.setAttribute("aria-activedescendant", firstElement.id);
        this.identifyOptions();
        this.resultsTarget.hidden = false;
        this.element.dispatchEvent(new CustomEvent("load"));
        this.element.dispatchEvent(new CustomEvent("loadend"));
      })
      .catch(() => {
        this.element.dispatchEvent(new CustomEvent("error"));
        this.element.dispatchEvent(new CustomEvent("loadend"));
      });
  }

  toggleV2SearchIcon() {
    const parentWrap = this.element.parentElement;
    const searchBar = parentWrap.lastElementChild.firstElementChild;
    const icon = searchBar
      .getElementsByClassName("search-modal__search-icon")[0]
      .getElementsByClassName("icon-search")[0];

    if (icon) {
      icon.classList.toggle("opacity-0");
    }
  }

  open() {
    if (!this.resultsTarget.hidden) return;
    this.resultsTarget.hidden = false;
    this.element.setAttribute("aria-expanded", "true");
    this.element.dispatchEvent(
      new CustomEvent("toggle", {
        detail: { input: this.input, results: this.results },
      }),
    );
  }

  close() {
    if (this.resultsTarget.hidden) return;
    this.resultsTarget.hidden = true;
    this.inputTarget.removeAttribute("aria-activedescendant");
    this.element.setAttribute("aria-expanded", "false");
    this.element.dispatchEvent(
      new CustomEvent("toggle", {
        detail: { input: this.input, results: this.results },
      }),
    );
  }

  get src() {
    return this.data.get("url");
  }

  get minLength() {
    const minLength = this.data.get("min-length");
    if (!minLength) {
      return 0;
    }
    return parseInt(minLength, 10);
  }
}
