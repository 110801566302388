import { Controller } from "stimulus";
import Trix from "trix";

export default class extends Controller {
  static targets = ["replyForm", "body"];

  validate(event) {
    this.element.dataset.buttonClicked = true;
    if (this.isRichTextProcessing || !this.bodyValue.length) {
      this.element.dataset.buttonClicked = false;
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }

  success(event) {
    this.bodyTarget.value = "";
    const commentPartialHtml = event.detail[0].comment_html;
    const postId = event.detail[0].post_id;
    const commentId = event.detail[0].comment.id;
    const parentCommentId = event.detail[0].parent_comment_id;
    const commentsWrapperDiv = document.querySelector(
      `.post__comments[data-post-id='${postId}'] .infinity_scroll`,
    );
    let insertAfterCommentDiv;

    if (parentCommentId) {
      const parentCommentWrapper = commentsWrapperDiv.getElementsByClassName(
        `comment--${parentCommentId}`,
      )[0];

      if (parentCommentWrapper.getElementsByClassName("post__replies")[0]) {
        insertAfterCommentDiv =
          parentCommentWrapper.getElementsByClassName("post__replies")[0]
            .lastChild.previousElementSibling;
        insertAfterCommentDiv.insertAdjacentHTML(
          "afterend",
          commentPartialHtml,
        );
      } else {
        insertAfterCommentDiv =
          parentCommentWrapper.getElementsByClassName("post__content")[0];
        insertAfterCommentDiv.insertAdjacentHTML(
          "afterend",
          "<div class='post__replies'>" + commentPartialHtml + "</div>",
        );
      }
    } else {
      if (commentsWrapperDiv.childElementCount != 0) {
        insertAfterCommentDiv =
          commentsWrapperDiv.lastChild.previousElementSibling;
        insertAfterCommentDiv.insertAdjacentHTML(
          "afterend",
          commentPartialHtml,
        );
      } else {
        insertAfterCommentDiv = commentsWrapperDiv;
        insertAfterCommentDiv.parentElement.classList.remove("hidden");
        insertAfterCommentDiv.innerHTML = commentPartialHtml;
      }
    }

    this.element.dataset.buttonClicked = false;
    const closeForm = this.bodyTarget.parentNode.parentNode.parentNode;

    closeForm.classList.add("short");

    if (!parentCommentId && closeForm.classList.contains("fixed-bottom")) {
      location.hash = "#comment_" + commentId;
    }

    const commentAddedDiv = commentsWrapperDiv.getElementsByClassName(
      `comment--${commentId}`,
    )[0];
    commentAddedDiv.classList.add("just-added");
    window.loadOembed();
  }

  get isRichTextProcessing() {
    return JSON.parse(this.bodyTarget.getAttribute("data-richtext-processing"));
  }

  get bodyValue() {
    return this.bodyTarget.value;
  }
  addFixedClass() {
    const footerDiv = document.getElementsByClassName("main__footer")[0];

    if (footerDiv) {
      const replyDiv = footerDiv.getElementsByClassName("post__reply")[0];
      const postWrapperDiv = document.getElementsByClassName("post__post")[0];
      const bottomOffset =
        footerDiv.getBoundingClientRect().top + replyDiv.clientHeight - 18;
      const windowHeight = window.innerHeight;

      if (bottomOffset >= windowHeight) {
        replyDiv.classList.add("fixed-bottom");
        replyDiv.style.width = postWrapperDiv.offsetWidth + "px";
      } else {
        replyDiv.classList.remove("fixed-bottom");
        replyDiv.style.width = null;
      }
    } else {
      window.removeEventListener("resize", this.addFixedClass);
      window.removeEventListener("scroll", this.addFixedClass);
    }
  }

  openShortForm(ev) {
    if (this.element.dataset.isLoggedIn == "true") {
      const commentWrapper = ev.target.parentNode.parentNode.parentNode;
      commentWrapper.classList.remove("short");
      const trixEditor = commentWrapper.getElementsByTagName("trix-editor")[0];
      trixEditor.focus();
    } else {
      window.location =
        this.element.dataset.signInPath +
        "?alert=Hey,+there!+%20+You+need+to+sign+in+to+write+a+comment.";
    }
  }

  closeShortForm(ev) {
    if (this.element.dataset.buttonClicked) {
      return;
    }

    setTimeout(function () {
      if (
        document.activeElement.classList.contains("btn-primary") ||
        document.activeElement.classList.contains("trix-content") ||
        document.activeElement.parentElement.classList.contains(
          "trix-content",
        ) ||
        document.activeElement.classList.contains("trix-button") ||
        document.activeElement.parentElement.className.indexOf("trix-dialog") !=
          -1
      ) {
        return;
      }
      ev.target.parentNode.parentNode.parentNode.classList.add("short");
    }, 1);
  }

  toggleReplyForm(ev) {
    ev.preventDefault();

    let replyForm;
    let replyToName;

    if (ev.target.parentElement.dataset.parentCommentId) {
      replyForm = document.getElementById(
        "form-reply-to-parent-" +
          ev.target.parentElement.dataset.parentCommentId,
      );
      replyToName = ev.target.parentElement.dataset.replyToName;
    } else if (ev.target.dataset.parentCommentId) {
      replyForm = document.getElementById(
        "form-reply-to-parent-" + ev.target.dataset.parentCommentId,
      );
      replyToName = ev.target.dataset.replyToName;
    } else {
      replyForm = this.replyFormTarget;
    }

    if (replyForm.classList.contains("hidden")) {
      replyForm.classList.remove("hidden");

      const trixEditor = replyForm.getElementsByTagName("trix-editor")[0];
      // Scroll to parent thread comment box (nested comment)
      const y =
        trixEditor.getBoundingClientRect().top + window.pageYOffset - 200;
      window.scrollTo({ top: y, behavior: "smooth" });
      trixEditor.editor.setSelectedRange([0, 10000]);
      trixEditor.editor.deleteInDirection("backward");
      trixEditor.focus();
      trixEditor.editor.setSelectedRange([0, 0]);

      if (replyToName && replyToName != "") {
        this.initializeReplyFormWithMention(trixEditor, replyToName);
      }
    } else {
      replyForm.classList.add("hidden");
    }
  }

  initializeReplyFormWithMention(trixEditor, replyToName) {
    fetch(
      `/users/mentions.json?query=${replyToName}&is_private_space=${trixEditor.dataset.isPrivateSpace}&space_id=${trixEditor.dataset.spaceId}`,
    )
      .then(response => response.json())
      .then(users => {
        let mentionUser;
        users.forEach(user => {
          if (user.just_name == replyToName) {
            mentionUser = user;
          }
        });

        const attachment = new Trix.Attachment({
          content: mentionUser.content,
          contentType: "mention",
          sgid: mentionUser.sgid,
        });
        trixEditor.editor.insertAttachment(attachment);
        trixEditor.editor.insertString(" ");
      });
  }
}
