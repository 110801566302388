import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.updateSpaceLastSeenPost();
  }

  updateSpaceLastSeenPost() {
    const { url, readCount, totalCount, spaceId } = this.element.dataset;

    if (url && parseInt(totalCount, 10) > parseInt(readCount, 10)) {
      fetch(url, {
        method: "PATCH",
        dataType: "script",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(() => {
          const countSelector = document.querySelectorAll(
            `.unread_posts_count_${spaceId}`,
          );
          if (countSelector.length > 0) {
            for (let i = 0; i < countSelector.length; i++) {
              countSelector[i].remove();
            }
          }
        });
    }
  }
}
