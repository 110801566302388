import { Controller } from "stimulus";

const spaceGroupSelector = ".block--space-group";

export default class extends Controller {
  static targets = ["parentDraggable"];

  connect() {
    this.excludeChildClass = ["nav-link", "space__name", "block-content"];
    this.currentTarget = null;
  }

  dragstart(event) {
    if (
      this.excludeChildClass.some(className =>
        event.target.classList.contains(className),
      )
    ) {
      this.currentTarget = null;
    } else {
      this.currentTarget = event.target.closest(spaceGroupSelector);
    }

    if (
      this.excludeChildClass.some(className =>
        event.target.classList.contains(className),
      )
    ) {
      return true;
    }

    event.dataTransfer.setData(
      "application/drag-key",
      this.currentTarget.getAttribute("data-id"),
    );
    event.dataTransfer.effectAllowed = "move";

    this.parentDraggableTargets.forEach((target, i) => {
      if (this.parentDraggableTargets.indexOf(this.currentTarget) != i) {
        target.childNodes.forEach(node => {
          if (node.nodeName != "#text") {
            node.style.pointerEvents = "none";
          }
        });
      }
    });
  }

  dragover(event) {
    event.preventDefault();

    if (!this.currentTarget) {
      return true;
    }

    if (
      this.parentDraggableTargets.indexOf(this.currentTarget) == -1 ||
      event.dataTransfer.getData("application/drag-key") != "" ||
      this.parentDraggableTargets.indexOf(
        event.target.closest(spaceGroupSelector),
      ) == this.parentDraggableTargets.indexOf(this.currentTarget)
    ) {
      return true;
    }

    if (
      this.parentDraggableTargets.indexOf(
        event.target.closest(spaceGroupSelector),
      ) < this.parentDraggableTargets.indexOf(this.currentTarget)
    ) {
      event.target.closest(spaceGroupSelector).classList.add("drag-over-top");
    } else {
      event.target
        .closest(spaceGroupSelector)
        .classList.add("drag-over-bottom");
    }

    return true;
  }

  dragenter(event) {
    event.preventDefault();
  }

  dragleave(event) {
    event.preventDefault();
    if (!this.currentTarget) {
      return true;
    }

    event.target.closest(spaceGroupSelector).classList.remove("drag-over-top");
    event.target
      .closest(spaceGroupSelector)
      .classList.remove("drag-over-bottom");
  }

  drop(event) {
    if (!this.currentTarget) {
      return true;
    }

    const dropTarget = event.target.closest(spaceGroupSelector);
    const draggedItem = this.currentTarget;

    if (!dropTarget || !draggedItem) {
      return true;
    }

    const positionComparison = dropTarget.compareDocumentPosition(draggedItem);

    if (positionComparison & 4) {
      dropTarget.insertAdjacentElement("beforebegin", draggedItem);
    } else if (positionComparison & 2) {
      dropTarget.insertAdjacentElement("afterend", draggedItem);
    }
    event.preventDefault();
  }

  dragend() {
    const orderArray = [];

    if (!this.currentTarget) {
      return true;
    }

    this.parentDraggableTargets.forEach((target, i) => {
      target.childNodes.forEach(node => {
        if (node.nodeName != "#text") {
          node.style.pointerEvents = "default";
        }
      });
      target.classList.remove("drag-over-top");
      target.classList.remove("drag-over-bottom");
      orderArray[i] = target.getAttribute("data-id");
    });

    fetch(this.element.dataset.saveUrl, {
      body: JSON.stringify({ order_array: orderArray }),
      method: "POST",
      dataType: "script",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(() => {
        var protocol = window.location.protocol,
          host = "//" + window.location.host,
          path = window.location.pathname,
          query = window.location.search;
        var newUrl = protocol + host + path + query;
        Turbolinks.visit(newUrl);
      });

    this.currentTarget = null;
  }
}
