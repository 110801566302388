import { Controller } from "stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static targets = [
    "likeButton",
    "likeCount",
    "likeWrapper",
    "likedByString",
    "currentUserAvatar",
    "likedByWrapper",
  ];

  like(ev) {
    ev.preventDefault();
    this.likeCount = parseInt(this.element.dataset.likeCount);
    this.incremented = this.element.dataset.incremented == "true";
    this.type = this.element.dataset.type;
    this.id = parseInt(this.element.dataset.id);

    if (this.incremented) {
      this.likeCount--;

      if (this.element.dataset.context == "comment") {
        this.performCommentDecrements();
        this.removeUserAvatar();
      } else if (this.element.dataset.context == "list") {
        this.performListDecrements();
      } else {
        this.performPostDecrements();
        if (this.likeCount < 3) {
          this.removeUserAvatar();
        }
      }

      this.postDecrementLike(this.id, this.type);
      this.removeLikedClass();
    } else {
      this.likeCount++;

      if (this.element.dataset.context == "comment") {
        this.performCommentIncrements();
        this.showUserAvatar();
      } else if (this.element.dataset.context == "list") {
        this.performListIncrements();
      } else {
        this.performPostIncrements();
        if (this.likeCount < 4) {
          this.showUserAvatar();
        }
      }

      this.postIncrementLike(this.id, this.type);
      this.addLikedClass();
    }

    this.element.dataset.incremented = !this.incremented;
    this.element.dataset.likeCount = this.likeCount;
  }

  postIncrementLike(id, type) {
    fetch(this.element.dataset.likeUrl, {
      body: JSON.stringify({
        user_like: {
          user_likeable_id: id,
          user_likeable_type: type,
        },
      }),
      method: "POST",
      dataType: "script",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  postDecrementLike(id, type) {
    fetch(this.element.dataset.likeUrl, {
      body: JSON.stringify({
        user_like: {
          user_likeable_id: id,
          user_likeable_type: type,
        },
      }),
      method: "DELETE",
      dataType: "script",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  performCommentIncrements() {
    if (this.likeCount == 1) {
      this.incrementOneCommentLike();
    } else if (this.likeCount > 1) {
      this.incrementCommentLikes();
    }
  }

  performCommentDecrements() {
    if (this.likeCount == 0) {
      this.resetLikeCountContent();
    } else {
      this.decrementCommentLikes();
    }
  }

  performPostIncrements() {
    if (this.likeCount == 1) {
      this.addLikedByCurrentUser();
    } else if (this.likeCount == 2) {
      this.addAndOther();
    } else if (this.likeCount > 2) {
      this.incrementAndOther();
    }
  }

  performPostDecrements() {
    if (this.likeCount == 0) {
      this.resetLikeWrapperContent();
    } else if (this.likeCount == 1) {
      this.removeAndOther();
    } else if (this.likeCount > 1) {
      this.decrementAndOther();
    }
  }
  performListIncrements() {
    this.incrementCount();
  }

  performListDecrements() {
    this.decrementCount();
  }

  addLikedClass() {
    for (var i = 0; i < this.likeButtonTargets.length; i++) {
      this.likeButtonTargets[i].classList.add("liked");
    }
  }

  removeLikedClass() {
    for (var i = 0; i < this.likeButtonTargets.length; i++) {
      this.likeButtonTargets[i].classList.remove("liked");
    }
  }

  showUserAvatar() {
    this.currentUserAvatarTarget.style.display = "inline";
  }

  removeUserAvatar() {
    this.currentUserAvatarTarget.style.display = "none";
  }

  // Post like updates

  resetLikeWrapperContent() {
    this.likedByStringTarget.innerHTML = "";
    this.likedByWrapperTarget.classList.add("no-padding");
  }

  resetLikeCountContent() {
    this.likeCountTarget.innerHTML = "";
  }

  addLikedByCurrentUser() {
    this.likedByStringTarget.style.display = "inline";
    this.likedByWrapperTarget.classList.remove("no-padding");
    this.likedByStringTarget.innerHTML =
      this.element.dataset.likedByString +
      " " +
      this.element.dataset.currentUserName;
  }

  addAndOther() {
    this.likedByStringTarget.style.display = "inline";
    this.likeCountTarget.style.display = "inline";
    this.likeCountTarget.innerHTML =
      "and 1 " + this.element.dataset.otherString;

    if (!this.likeCountTarget._tippy) {
      tippy(this.likeCountTarget);
    }

    if (
      this.likedByStringTarget.innerHTML.indexOf(
        this.element.dataset.currentUserName,
      ) == -1
    ) {
      this.updateTooltip(
        this.likeCountTarget,
        this.likeCountTarget.dataset.tippyContent +
          this.element.dataset.currentUserName,
        this.element.dataset.currentUserName,
      );
    }
  }

  removeAndOther() {
    this.likedByStringTarget.style.display = "none";
    this.likeCountTarget.innerHTML =
      "Liked by 1 " + this.element.dataset.otherString;
    const instance = this.likeCountTarget._tippy;
    instance.destroy();
  }

  incrementAndOther() {
    const count = this.likeCount - 1;
    this.likeCountTarget.style.display = "inline";
    this.likeCountTarget.innerHTML =
      this.element.dataset.andString +
      " " +
      count +
      " " +
      this.element.dataset.othersString;

    if (!this.likeCountTarget._tippy) {
      tippy(this.likeCountTarget);
    }

    this.updateTooltip(
      this.likeCountTarget,
      this.likeCountTarget.dataset.tippyContent +
        this.element.dataset.currentUserName,
      this.element.dataset.currentUserName,
    );
  }

  decrementAndOther() {
    const count = this.likeCount - 1;
    this.likeCountTarget.style.display = "inline";
    this.likeCountTarget.innerHTML =
      this.element.dataset.andString +
      " " +
      count +
      " " +
      this.element.dataset.othersString;
    this.updateTooltip(
      this.likeCountTarget,
      this.likeCountTarget.dataset.tippyContent.replace(
        this.element.dataset.currentUserName + "<br>",
        "",
      ),
    );
  }

  // Comment like updates

  incrementOneCommentLike() {
    this.likeCountTarget.style.display = "inline";
    this.likeCountTarget.innerHTML = "1 like";
    this.updateTooltip(
      this.likeCountTarget,
      this.likeCountTarget.dataset.tippyContent +
        this.element.dataset.currentUserName,
      this.element.dataset.currentUserName,
    );
  }

  incrementCommentLikes() {
    this.likeCountTarget.style.display = "inline";
    this.likeCountTarget.innerHTML = this.likeCount + " likes";
    this.updateTooltip(
      this.likeCountTarget,
      this.likeCountTarget.dataset.tippyContent +
        this.element.dataset.currentUserName,
      this.element.dataset.currentUserName,
    );
  }

  decrementCommentLikes() {
    this.likeCountTarget.style.display = "inline";
    this.updateTooltip(
      this.likeCountTarget,
      this.likeCountTarget.dataset.tippyContent.replace(
        this.element.dataset.currentUserName + "<br>",
        "",
      ),
      this.element.dataset.currentUserName,
    );
    this.likeCountTarget.innerHTML = this.likeCount + " likes";
  }

  updateTooltip(element, newText, updateString) {
    if (element.dataset.tippyContent.indexOf(updateString) == -1) {
      const tooltip = element._tippy;

      if (tooltip) {
        tooltip.setContent(newText);
      }
    }
  }

  incrementCount() {
    const count = this.likeCount;
    this.likeCountTarget.style.display = "inline";
    this.likeCountTarget.innerHTML = count;
  }

  decrementCount() {
    const count = this.likeCount;
    this.likeCountTarget.style.display = "inline";
    this.likeCountTarget.innerHTML = count;
  }

  expandCommentForm() {
    const form = document.getElementById("comment-form");
    const trixEditor = form.querySelector("trix-editor");
    const trixV1 = form.classList.contains("short");

    form.scrollIntoView();

    setTimeout(() => {
      trixEditor.editor.setSelectedRange([0, 0]);
    }, 100);

    if (trixV1) form.classList.remove("short");
  }
}
