import debounce from "lodash.debounce";
import { Controller } from "stimulus";
import Tribute from "tributejs";
import Trix from "trix";
import { TrixApiWrapper } from "../react/components/shared/TrixEditor/TrixApiWrapper";
import { AttachmentUpload } from "../src/AttachmentUpload";

const trixActiveClass = "trix-active";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    this.editor = this.fieldTarget.editor;
    this.uploadingFilesCount = 0;
    this.processing = false;
    if (this.element.dataset.mentionsEnabled) {
      this.initializeTribute();
    }

    if (this.element.dataset.embedsEnabled) {
      this.activateEmbed();
    }

    this.activeHr();

    this.overrideTheAttachmentBehaviour();
  }

  overrideTheAttachmentBehaviour() {
    this.fieldTarget.addEventListener("trix-file-accept", event => {
      event.preventDefault();
      this.incrementUploadingFileCount();
      const uploader = new AttachmentUpload(
        event.file,
        this.fieldTarget,
        this.decrementUploadingFileCount.bind(this),
      );
      uploader.start();
    });

    this.fieldTarget.addEventListener("trix-change", event => {
      // in safari when a user copies and paste content from another editor - ex. form whimsical or pages
      // The images are attached as blob urls which break the backend.
      // This is to fix that.
      // We remove such attachments on trix change.
      const editor = event.target.editor;
      let currentPosition = editor.getPosition() || 0;
      editor.composition.attachments.forEach(attachment => {
        if (
          attachment.attributes.values.url &&
          attachment.attributes.values.url.startsWith("blob")
        ) {
          const range = editor.getDocument().getRangeOfAttachment(attachment);
          editor.setSelectedRange(range);
          editor.deleteInDirection("backward");
          if (currentPosition > range[1]) {
            currentPosition--;
          }
        }
      });
      currentPosition = Math.max(currentPosition, 0);
      editor.setSelectedRange([currentPosition, currentPosition]);
    });
  }

  set processing(value) {
    this.data.set("processing", value);
  }

  removeAttachment = event => {
    event.preventDefault();
    const attachmentElement = event.target.closest("[data-trix-attachment]");
    const trixApiWrapper = new TrixApiWrapper(this.editor);
    trixApiWrapper.recordUndoEntry("remove attachment");
    trixApiWrapper.removeAttachmentById(
      parseInt(attachmentElement.dataset.trixId, 10),
    );
  };
  incrementUploadingFileCount() {
    this.uploadingFilesCount++;
    this.processing = this.uploadingFilesCount > 0;
  }

  decrementUploadingFileCount() {
    this.uploadingFilesCount--;
    this.processing = this.uploadingFilesCount > 0;
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: "just_name",
      values: this.fetchUsers.bind(this),
      menuItemTemplate: function (item) {
        return item.original.name;
      },
    });
    this.tribute.attach(this.fieldTarget);
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced);
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
  }

  disconnect() {
    if (this.tribute) {
      this.tribute.detach(this.fieldTarget);
    }
  }

  fetchUsers(text, callback) {
    fetch(
      `/users/mentions.json?query=${text}&is_private_space=${this.element.dataset.isPrivateSpace}&space_id=${this.element.dataset.spaceId}`,
    )
      .then(response => response.json())
      .then(users => callback(users))
      .catch(() => callback([]));
  }

  replaced(e) {
    const mention = e.detail.item.original;
    const attachment = new Trix.Attachment({
      content: mention.content,
      contentType: "mention",
      sgid: mention.sgid,
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(" ");
  }

  _pasteHtml(html, startPos, endPos) {
    const position = this.editor.getPosition();
    this.editor.setSelectedRange([position - endPos, position]);
    this.editor.deleteInDirection("backward");
  }

  activeHr() {
    this.element.addEventListener("trix-initialize", event => {
      const { toolbarElement } = event.target;
      const blockTools = toolbarElement.querySelector(
        "[data-trix-button-group=block-tools]",
      );
      blockTools.insertAdjacentHTML(
        "afterbegin",
        `
        <button type="button" class="trix-button" data-trix-action="x-horizontal-rule" title="Horizontal Rule" tabindex="-1">━</button>
      `,
      );
    });

    this.element.addEventListener("trix-action-invoke", event => {
      if (event.actionName == "x-horizontal-rule") {
        const { editor } = event.target;
        const attachment = new Trix.Attachment({
          content: "<hr>",
          contentType: "vnd.rubyonrails.horizontal-rule.html",
        });
        editor.insertAttachment(attachment);
        this.fieldTarget.dispatchEvent(new Event("change"));
      }
    });
  }

  activateEmbed() {
    const existingEmbedButton =
      this.fieldTarget.toolbarElement.querySelector(".trix-embed-button");
    const existingEmbedDialog = this.fieldTarget.toolbarElement.querySelector(
      ".trix-dialog--embed",
    );

    this.editor = this.fieldTarget.editor;

    const buttonHTML =
      '<button type="button" class="trix-button trix-embed-button" data-trix-attribute="embed" data-trix-action="embed" title="Embed" tabindex="-1">Embed</button>';
    const buttonGroup = this.fieldTarget.toolbarElement.querySelector(
      ".trix-button-group--block-tools",
    );
    const dialogHml = `<div class="trix-dialog trix-dialog--link trix-dialog--embed" data-trix-dialog="embed" data-trix-dialog-attribute="embed">
    <div class="trix-dialog__link-fields">
    <input type="text" name="embed" class="trix-input trix-input--dialog" placeholder="Paste a YouTube, Vimeo, Twitter, Facebook, Instagram, or other URL" aria-label="embed code" required="" data-trix-input="" disabled="disabled">
      <div class="trix-button-group">
        <input type="button" class="trix-button trix-button--dialog" data-trix-custom="add-embed" value="Add">
      </div>
    </div>
  </div>`;
    const dialogGroup =
      this.fieldTarget.toolbarElement.querySelector(".trix-dialogs");

    if (!existingEmbedButton && !existingEmbedDialog) {
      buttonGroup.insertAdjacentHTML("beforeend", buttonHTML);
      dialogGroup.insertAdjacentHTML("beforeend", dialogHml);
    }

    this.element.parentElement
      .querySelector('[data-trix-action="embed"]')
      .addEventListener(
        "click",
        function (event) {
          this.debouncedScrollHandler = debounce(() => {
            const dialog = this.element.parentElement.querySelector(
              '[data-trix-dialog="embed"]',
            );
            const embedInput =
              this.element.parentElement.querySelector('[name="embed"]');
            if (event.target.classList.contains(trixActiveClass)) {
              event.target.classList.remove(trixActiveClass);
              dialog.classList.remove(trixActiveClass);
              dialog.dataset.trixActive = false;
              embedInput.blur();
              dialog.removeAttribute("data-trix-active");
              embedInput.setAttribute("disabled", "disabled");
            } else {
              event.target.classList.add(trixActiveClass);
              dialog.classList.add(trixActiveClass);
              dialog.dataset.trixActive = true;
              embedInput.removeAttribute("disabled");
              embedInput.focus();
            }
          }, 50);

          this.debouncedScrollHandler();
        }.bind(this),
      );

    this.element.parentElement
      .querySelector('[data-trix-custom="add-embed"]')
      .addEventListener(
        "click",
        function () {
          const content =
            this.element.parentElement.querySelector('[name="embed"]').value;
          if (content) {
            fetch(
              this.element.parentElement.querySelector("[data-embeds-path]")
                .dataset.embedsPath,
              {
                body: JSON.stringify({
                  embed: {
                    content: content,
                    embed_format: "oembed",
                  },
                }),
                method: "POST",
                dataType: "script",
                credentials: "include",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              },
            )
              .then(response => response.json())
              .then(response => {
                const attachment = new Trix.Attachment({
                  content: response.content,
                  contentType: "embed",
                  sgid: response.sgid,
                });
                this.editor.insertAttachment(attachment);
                this.editor.insertLineBreak();
                this.fieldTarget.dispatchEvent(new Event("change"));
              });
          }

          const embedAction = this.element.parentElement.querySelector(
            '[data-trix-action="embed"]',
          );
          const dialog = this.element.parentElement.querySelector(
            '[data-trix-dialog="embed"]',
          );
          const embedInput =
            this.element.parentElement.querySelector('[name="embed"]');

          if (embedAction.classList.contains(trixActiveClass)) {
            embedAction.classList.remove(trixActiveClass);
            dialog.classList.remove(trixActiveClass);
            delete dialog.dataset.trixActive;
            embedInput.setAttribute("disabled", "disabled");
          }
        }.bind(this),
      );
  }
  activateAutoLinker() {
    document.execCommand("AutoUrlDetect", false, false); // turn off browser's auto linking
    var trix_link_changing;
    this.element.addEventListener("trix-change", function () {
      const blacklist = ["e.g.", "i.e.", "e.g", "i.e"];
      if (trix_link_changing === true) return;
      var element = document.querySelector("trix-editor");
      var where = element.editor.getSelectedRange();
      var str = element.editor.getDocument().toString();

      var last = str.substring(where[0] - 1, where[0]);

      if (last.match(/\s/) === null) return true;

      var lastwhere = str.lastIndexOf(" ", where[0] - 2);

      var word = str.substring(lastwhere + 1, where[0] - 1);

      if (
        !word.match(/^(http:\/\/|https:\/\/)?[a-z0-9\-.]+?\.[a-z]+[/]?.*$/i) ||
        blacklist.includes(word)
      )
        return true;

      element.editor.setSelectedRange([lastwhere + 1, where[0] - 1]);

      if (element.editor.attributeIsActive("href") !== false) {
        element.editor.setSelectedRange([where[0], where[1]]);
        return true;
      }

      // format link
      var url = word;
      if (url.indexOf("://") == -1) url = "https://" + url;

      // format title
      var title = word
        .replace("http://", "")
        .replace("https://", "")
        .replace(/^www\./, "");
      if (title.length > 40) title = title.substr(0, 37) + "...";
      var newwhere = where[0] - (word.length - title.length);

      trix_link_changing = true;
      element.editor.recordUndoEntry("Auto Link");
      element.editor.deleteInDirection("forward");
      element.editor.insertString(title);
      element.editor.setSelectedRange([
        lastwhere + 1,
        lastwhere + 1 + title.length,
      ]);
      element.editor.activateAttribute("href", url);
      element.editor.setSelectedRange([newwhere - 1, newwhere - 1]);
      element.editor.setSelectedRange([newwhere, newwhere]);

      trix_link_changing = false;
      return true;
    });
  }
}
