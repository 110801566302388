import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "tabPanel"];

  initialize() {
    this.showTab(this.element.dataset.currentTabIndex || 0);
  }

  change(e) {
    e.preventDefault();
    this.index = this.tabTargets.indexOf(e.target.dataset.adminTabsIndex);
    this.showTab(e.target.dataset.adminTabsIndex);
  }

  showTab(tabIndex) {
    this.tabPanelTargets.forEach(el => {
      if (el.dataset.adminTabsIndex == tabIndex) {
        el.classList.remove("hidden");
      } else {
        el.classList.add("hidden");
      }
    });

    this.tabTargets.forEach(el => {
      if (el.dataset.adminTabsIndex == tabIndex) {
        el.classList.remove(
          "border-transparent",
          "text-gray-500",
          "hover:text-gray-700",
          "hover:border-gray-300",
        );
        el.classList.add("active", "border-indigo-500", "text-indigo-600");
      } else {
        el.classList.remove("active", "border-indigo-500", "text-indigo-600");
        el.classList.add(
          "border-transparent",
          "text-gray-500",
          "hover:text-gray-700",
          "hover:border-gray-300",
        );
      }
    });
  }
}
