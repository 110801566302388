import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submenu", "caret"];

  toggle() {
    this.submenuTarget.classList.toggle("hidden");
    this.caretTarget.classList.toggle("rotate-90");
    if (this.caretTarget.classList.contains("text-gray-400")) {
      this.caretTarget.classList.remove("text-gray-400");
      this.caretTarget.classList.add("text-gray-300");
    } else if (this.caretTarget.classList.contains("text-gray-300")) {
      this.caretTarget.classList.remove("text-gray-300");
      this.caretTarget.classList.add("text-gray-400");
    }
  }
}
