import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.loadData();
  }

  loadData = async () => {
    const url = this.data.get("url");
    const response = await fetch(url);
    if (response.ok) {
      this.element.innerHTML = await response.text();
    }
  };
}
