import slugify from "slugify";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "preview",
    "file",
    "inputName",
    "outputSlug",
    "button",
    "removeImageButton",
  ];

  previewImage() {
    const file = this.fileTarget.files[0];
    const previewSelector = this.previewTarget;
    const reader = new FileReader();

    previewSelector.style.display = "block";

    reader.onloadend = function () {
      previewSelector.src = reader.result;
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      previewSelector.src = "";
    }

    this.buttonTarget.innerHTML = this.buttonTarget.dataset.buttonText;
  }

  nameToSlug() {
    this.outputSlugTarget.value = slugify(this.inputNameTarget.value, {
      replacement: "-",
      remove: /[*+~.;()'"!:@]/g,
      lower: true,
    });
  }

  selfToSlug() {
    this.outputSlugTarget.value = slugify(this.outputSlugTarget.value, {
      replacement: "-",
      remove: /[*+~.;()'"!:@]/g,
      lower: true,
    });
  }

  deleteImage(ev) {
    ev.preventDefault();
    fetch(this.element.dataset.deleteImagePath, {
      method: "DELETE",
      dataType: "script",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          this.previewTarget.src = "";
          this.previewTarget.classList.add("hidden");
          this.removeImageButtonTarget.remove();
          this.buttonTarget.innerHTML = this.element.dataset.btnUpdateText;
          this.buttonTarget.parentElement.classList.remove("btn-half");
          this.buttonTarget.parentElement.classList.add("btn-full");
        }
      });
  }
}
