// app/javascript/packs/controllers/time_controller.js
import { Controller } from "stimulus";

const timeAgo = require("node-time-ago");

export default class extends Controller {
  static targets = ["timestamp"];

  connect() {
    const timestamp = this.element.dataset.timestamp;
    this.element.innerHTML = timeAgo(new Date(timestamp));
  }
}
