import SlimSelect from "slim-select";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slimSelect"];

  connect() {
    new SlimSelect({
      select: this.slimSelectTarget,
      placeholder: this.element.dataset.placeholder,
      searchText: this.element.dataset.searchText,
      searchPlaceholder: this.element.dataset.searchPlaceholder
        ? this.element.dataset.searchPlaceholder
        : "",
      showContent: this.element.dataset.direction
        ? this.element.dataset.direction
        : "up",
      addable: function (value) {
        if (this.element.dataset.addable) {
          return {
            text: value,
            value: value,
          };
        } else {
          return false;
        }
      }.bind(this),
      allowDeselect: true,
      closeOnSelect: this.element.dataset.disableAutoReopen
        ? this.element.dataset.disableAutoReopen
        : false,
      limit: this.element.dataset.limit ? this.element.dataset.limit : false,
    });
  }
}
