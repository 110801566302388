import { Controller } from "stimulus";
import { PASSWORD_REGEX } from "@circle-react/components/OnboardingPage/helpers/constants";

const isInvalidClass = "is-invalid";

export default class extends Controller {
  static targets = [
    "passwordField",
    "confirmPasswordField",
    "passwordErrorMessage",
    "confirmPasswordErrorMessage",
    "form",
    "nameErrorMessage",
    "nameField",
  ];

  checkPassword(str) {
    return PASSWORD_REGEX.test(str);
  }

  validatePassword() {
    if (this.checkPassword(this.passwordFieldTarget.value)) {
      return;
    } else {
      this.passwordErrorMessageTarget.classList.remove("hidden");
      this.passwordFieldTarget.classList.add(isInvalidClass);
    }
  }

  validateConfirmPassword() {
    if (
      this.passwordFieldTarget.value == this.confirmPasswordFieldTarget.value
    ) {
      return;
    } else {
      this.confirmPasswordErrorMessageTarget.classList.remove("hidden");
      this.confirmPasswordFieldTarget.classList.add(isInvalidClass);
    }
  }

  resetPasswordValidation() {
    this.passwordErrorMessageTarget.classList.add("hidden");
    this.passwordFieldTarget.classList.remove(isInvalidClass);
  }

  resetConfirmPasswordValidation() {
    this.confirmPasswordErrorMessageTarget.classList.add("hidden");
    this.confirmPasswordFieldTarget.classList.remove(isInvalidClass);
  }

  resetNameValidation() {
    this.nameErrorMessageTarget.classList.add("hidden");
    this.nameFieldTarget.classList.remove(isInvalidClass);
  }

  submitForm(event) {
    event.preventDefault();
    if (document.getElementById("confirmPassword")) {
      if (
        this.passwordFieldTarget.value == this.confirmPasswordFieldTarget.value
      ) {
        this.validateAndFormSubmit();
      } else {
        this.confirmPasswordErrorMessageTarget.classList.remove("hidden");
        this.confirmPasswordFieldTarget.classList.add(isInvalidClass);
      }
    } else {
      this.validateAndFormSubmit();
    }
  }

  validateAndFormSubmit() {
    if (this.checkPassword(this.passwordFieldTarget.value)) {
      if (window.RAILS_ENV === "production" || window.RAILS_ENV === "staging") {
        grecaptcha.execute();
      } else {
        this.formTarget.submit();
      }
    } else {
      this.passwordErrorMessageTarget.classList.remove("hidden");
      this.passwordFieldTarget.classList.add(isInvalidClass);
    }
  }

  validateName() {
    if (this.nameFieldTarget.value.length > 0) {
      return;
    } else {
      this.nameErrorMessageTarget.classList.remove("hidden");
      this.nameFieldTarget.classList.add(isInvalidClass);
    }
  }
}
