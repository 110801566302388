import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["draggable"];

  connect() {
    this.excludeChildClass = [
      "block-header",
      "block-header__title",
      "block-header__actions",
      "block--space-group",
    ];
  }

  dragstart(event) {
    if (
      this.excludeChildClass.some(className =>
        event.target.classList.contains(className),
      )
    ) {
      this.currentTarget = null;
    } else {
      this.currentTarget = event.target;
    }

    this.currentTarget.classList.add("selected");

    event.dataTransfer.effectAllowed = "move";
  }

  dragover(event) {
    event.preventDefault();

    if (!this.currentTarget) {
      return true;
    }

    const dropTarget = event.target.closest(".nav-link");

    if (
      this.draggableTargets.indexOf(event.target) <
      this.draggableTargets.indexOf(this.currentTarget)
    ) {
      dropTarget.classList.add("drag-over-top");
    } else {
      dropTarget.classList.add("drag-over-bottom");
    }

    return true;
  }

  dragenter(event) {
    event.preventDefault();
  }

  dragleave(event) {
    event.preventDefault();
    const dropTarget = event.target.closest(".nav-link");

    dropTarget.classList.remove("drag-over-top");
    dropTarget.classList.remove("drag-over-bottom");
  }

  drop(event) {
    if (!this.currentTarget) {
      return true;
    }

    const dropTarget = event.target.closest(".nav-link");
    const draggedItem = this.currentTarget;

    if (!dropTarget || !draggedItem) {
      return true;
    }

    const positionComparison = dropTarget.compareDocumentPosition(draggedItem);

    if (positionComparison & 4) {
      dropTarget.insertAdjacentElement("beforebegin", draggedItem);
    } else if (positionComparison & 2) {
      dropTarget.insertAdjacentElement("afterend", draggedItem);
    }
    event.preventDefault();
  }

  dragend() {
    const orderArray = [];

    if (!this.currentTarget) {
      return true;
    }

    this.draggableTargets.forEach((target, i) => {
      target.classList.remove("drag-over-top");
      target.classList.remove("drag-over-bottom");
      orderArray[i] = target.getAttribute("data-id");
    });

    fetch(this.element.dataset.saveUrl, {
      body: JSON.stringify({ order_array: orderArray }),
      method: "POST",
      dataType: "script",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(() => {
        var protocol = window.location.protocol,
          host = "//" + window.location.host,
          path = window.location.pathname,
          query = window.location.search;
        var newUrl = protocol + host + path + query;
        window.history.pushState({ path: newUrl }, "", newUrl);
      });
    // Turbolinks.visit(window.location);

    setTimeout(
      function () {
        this.currentTarget.classList.remove("selected");
      }.bind(this),
      500,
    );
  }
}
