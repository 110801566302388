import { Controller } from "stimulus";

const sidebarFloatingClass = "sidebar--floating";
const hasSidebarClass = "has-sidebar";
const headerFloatingClass = "header--floating";

export default class extends Controller {
  static targets = ["layout", "sidebar", "sidebarBtn", "arrowIcon", "menuIcon"];

  connect() {
    if (
      this.hasLayoutTarget &&
      this.hasSidebarTarget &&
      this.hasSidebarBtnTarget
    ) {
      this.initMenu(
        this.layoutTarget,
        this.sidebarTarget,
        this.sidebarBtnTarget,
      );
    }

    this.hideFloating();
  }

  toggleSidebarIcon() {
    function adjustIcon(sidebar, arrowIcon, menuIcon) {
      if (sidebar.classList.contains(sidebarFloatingClass)) {
        arrowIcon.classList.add("hidden");
        menuIcon.classList.remove("hidden");
      } else {
        arrowIcon.classList.remove("hidden");
        menuIcon.classList.add("hidden");
      }
    }

    adjustIcon(this.sidebarTarget, this.arrowIconTarget, this.menuIconTarget);

    window.addEventListener("resize", () => {
      adjustIcon(this.sidebarTarget, this.arrowIconTarget, this.menuIconTarget);
    });
  }

  initMenu(layout, sidebar, sidebarBtn) {
    const x = window.matchMedia("(min-width: 992px)");

    window.addEventListener("resize", () => {
      if (!x.matches && sidebar.classList.contains(sidebarFloatingClass)) {
        layout.classList.remove(hasSidebarClass);
      } else if (
        !x.matches &&
        !sidebar.classList.contains(sidebarFloatingClass)
      ) {
        layout.classList.add(hasSidebarClass);
        sidebar.classList.remove("hidden");
      } else if (x.matches) {
        sidebar.classList.remove("sidebar--mobile-open");
      }

      if (
        !x.matches &&
        sidebarBtn.parentElement.classList.contains(headerFloatingClass)
      ) {
        sidebarBtn.parentElement.classList.remove(headerFloatingClass);
      } else if (
        x.matches &&
        sidebar.classList.contains(sidebarFloatingClass)
      ) {
        sidebarBtn.parentElement.classList.add(headerFloatingClass);
      }
    });
  }

  toggleFloating() {
    //TODO: Set cookies

    this.toggleSidebarIcon();

    function detectMobileMenu(sidebar, sidebarBtn, layout) {
      const x = window.matchMedia("(min-width: 992px)");
      if (x.matches) {
        sidebar.classList.toggle(sidebarFloatingClass);
        sidebar.classList.remove("sidebar--mobile-open");
        sidebarBtn.parentElement.classList.toggle(headerFloatingClass);
      } else {
        sidebar.classList.remove(sidebarFloatingClass);
        sidebar.classList.toggle("sidebar--mobile-open");
        sidebarBtn.parentElement.classList.remove(headerFloatingClass);
        layout.classList.remove(hasSidebarClass);
      }
    }

    if (this.hasSidebarTarget && this.hasLayoutTarget) {
      this.layoutTarget.classList.toggle(hasSidebarClass);

      detectMobileMenu(
        this.sidebarTarget,
        this.sidebarBtnTarget,
        this.layoutTarget,
      );
    }
  }

  showFloating() {
    if (this.hasSidebarTarget) {
      this.sidebarTarget.classList.remove("hidden");
    }
  }

  hideFloating() {
    this.toggleSidebarIcon();

    window.addEventListener("mouseover", e => {
      if (
        this.sidebarTarget.contains(e.target) ||
        this.sidebarBtnTarget.contains(e.target)
      ) {
        this.sidebarTarget.classList.remove("hidden");
      } else if (!this.layoutTarget.classList.contains(hasSidebarClass)) {
        this.sidebarTarget.classList.add("hidden");
      }
    });
  }
}
