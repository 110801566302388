import flatpickr from "flatpickr";
import { Controller } from "stimulus";
import { getFormattedDate } from "../src/helpers/dateHelper";

export default class extends Controller {
  static targets = ["dateInput"];

  connect() {
    if (this.element.dataset.timeSelect) {
      flatpickr(this.element, {
        enableTime: true,
        dateFormat: "F j, Y G:i K",
        maxDate: "today",
      });
    } else {
      flatpickr(this.element, {
        mode: "range",
        dateFormat: "j M Y",
        maxDate: "today",
        onClose: function (selectedDates) {
          if ("URLSearchParams" in window) {
            var searchParams = new URLSearchParams(window.location.search);
            searchParams.set(
              this.element.dataset.minDateParam,
              getFormattedDate(selectedDates[0]).replace(/\//g, "-"),
            );
            searchParams.set(
              this.element.dataset.maxDateParam,
              getFormattedDate(selectedDates[1]).replace(/\//g, "-"),
            );
            window.location.search = searchParams.toString();
          }
        }.bind(this),
      });
    }
  }
}
