import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["home", "notifications", "search", "messages", "addPost"];

  connect() {
    this.homeTarget.classList.contains("active");
  }

  openHome(event) {
    event.preventDefault();

    if (this.homeTarget.classList.contains("active")) {
      this.scrollToTop();
    } else {
      this.homeTarget.classList.add("active");
    }
    this.closeAllDropdowns();
    this.markAllExceptInactive(this.homeTarget);
    this.removeMobileBodyNoscroll();
  }

  openSearch(event) {
    event.preventDefault();
    this.closeAllDropdowns();
    this.searchTarget.classList.add("active");
    document
      .getElementById("search-window-dropdown")
      .classList.remove("hidden");
    this.focusSearchInput();
    this.markAllExceptInactive(this.searchTarget);
    this.addMobileBodyNoscroll();
  }

  openAddPost(event) {
    this.openHome(event);
    const homeButton = document.getElementById(
      "sidebar--right__btn-quick-post",
    );
    const spaceButton = document.getElementById("space__action__btn-new-post");

    if (homeButton) {
      homeButton.click();
    } else if (spaceButton) {
      spaceButton.click();
    }
  }

  openNotifications(event) {
    event.preventDefault();
    this.closeAllDropdowns();
    this.notificationsTarget.classList.add("active");
    this.markAllExceptInactive(this.notificationsTarget);
    this.addMobileBodyNoscroll();
  }

  openMessages(event) {
    event.preventDefault();
    this.messagesTarget.classList.add("active");
    this.markAllExceptInactive(this.messagesTarget);
    this.addMobileBodyNoscroll();
  }

  addMobileBodyNoscroll() {
    document.body.classList.add("fixed");
  }

  removeMobileBodyNoscroll() {
    document.body.classList.remove("fixed");
  }

  markAllExceptInactive(except) {
    const menuItems = [
      this.searchTarget,
      this.notificationsTarget,
      this.homeTarget,
      this.addPostTarget,
    ];

    if (this.hasMessagesTarget) {
      menuItems.push(this.messagesTarget);
    }

    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i] !== except) {
        menuItems[i].classList.remove("active");
      }
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  closeAllDropdowns() {
    const dropdownMenus = document.getElementsByClassName(
      "dropdown-menu--bottom-bar",
    );

    for (let i = 0; i < dropdownMenus.length; i++) {
      dropdownMenus[i].classList.add("hidden");
    }
  }

  focusSearchInput() {
    document.getElementsByClassName("bottom-bar-search-input")[0].focus();
  }
}
