import { Controller } from "stimulus";
import { PASSWORD_REGEX } from "@circle-react/components/OnboardingPage/helpers/constants";

const borderDangerClasses = "form-control--has-error-v2";
const hiddenClasses = "invisible";

export default class extends Controller {
  connect() {
    this.passwordField = document.getElementById("user_password");
    this.passwordConfirmationField = document.getElementById(
      "user_password_confirmation",
    );
    this.saveButton = document.getElementById("save-button");

    this.passwordField.addEventListener("keyup", this.onFieldKeyUp);
    this.passwordConfirmationField.addEventListener("keyup", this.onFieldKeyUp);

    this.passwordFormatError = document.getElementById("password-format-error");
    this.passwordConfirmationError = document.getElementById(
      "password-confirmation-error",
    );
  }

  disconnect() {
    this.passwordField.removeEventListener("keyup", this.onPasswordFieldKey);
    this.passwordConfirmationField.removeEventListener(
      "keyup",
      this.onPasswordConfirmFieldKeyUp,
    );
  }

  onFieldKeyUp = () => {
    const passwordsAreEqual = this.checkPasswordAreEqual();
    const formatIsSecure = this.checkPasswordFormat();

    this.updateFieldsErrorClasses({
      passwordHasError: !formatIsSecure,
      confirmationHasError: !passwordsAreEqual,
    });
  };

  checkPasswordFormat = () => {
    if (!this.passwordField.value) {
      return true;
    }
    return PASSWORD_REGEX.test(this.passwordField.value);
  };

  checkPasswordAreEqual = () => {
    return this.passwordField.value === this.passwordConfirmationField.value;
  };

  updateFieldsErrorClasses = ({
    passwordHasError = false,
    confirmationHasError = false,
  }) => {
    const passwordField = this.passwordField;
    const confirmationField = this.passwordConfirmationField;
    const passwordError = this.passwordFormatError;
    const confirmationError = this.passwordConfirmationError;

    if (passwordHasError && passwordField.value.length > 0) {
      passwordField.classList.add(borderDangerClasses);
      passwordError.classList.remove(hiddenClasses);
    } else {
      passwordField.classList.remove(borderDangerClasses);
      passwordError.classList.add(hiddenClasses);
    }

    if (confirmationHasError && confirmationField.value.length > 0) {
      confirmationField.classList.add(borderDangerClasses);
      confirmationError.classList.remove(hiddenClasses);
    } else {
      confirmationField.classList.remove(borderDangerClasses);
      confirmationError.classList.add(hiddenClasses);
    }

    if (passwordHasError || confirmationHasError) {
      this.saveButton.setAttribute("disabled", true);
    } else {
      this.saveButton.removeAttribute("disabled");
    }
  };
}
