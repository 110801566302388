import ColorPicker from "simple-color-picker";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["colorPicker", "colorPreview", "colorInput"];

  connect() {
    this.initializeColorPicker();
    this.colorPicker.setColor(this.element.dataset.defaultColor);
  }

  initializeColorPicker() {
    this.colorPicker = new ColorPicker();
    this.colorPicker.setColor(this.colorInputTarget.value);
    this.colorPicker.onChange(this.updateColors.bind(this));
  }

  loadColorPicker() {
    this.colorPicker.appendTo(this.colorPickerTarget);
  }

  focusColorPickerInput() {
    this.colorInputTarget.focus();
  }

  updateColors() {
    this.colorHex = this.colorPicker.getHexString();
    this.colorPreviewTarget.style.background = this.colorHex;
    this.colorInputTarget.value = this.colorHex;
  }

  removeColorPicker() {
    this.colorPreviewTarget.style.background = this.colorInputTarget.value;
    this.colorPicker.remove();
    this.initializeColorPicker();
  }
}
