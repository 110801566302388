import { Controller } from "stimulus";

export default class extends Controller {
  submitForm(event) {
    if (window.RAILS_ENV === "production" || window.RAILS_ENV === "staging") {
      event.preventDefault();
      grecaptcha.execute();
    } else {
      this.formTarget.submit();
    }
  }
}
