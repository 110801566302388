import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.submitFormShortcut();
  }

  submitFormShortcut() {
    // Control/Cmd + Enter form submit
    const form = this.element;

    document.addEventListener("keydown", function (e) {
      if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) form.submit();
    });
  }

  validate(event) {
    if (this.isRichTextProcessing) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }

  get isRichTextProcessing() {
    return JSON.parse(
      this.element
        .querySelector("trix-editor")
        .getAttribute("data-richtext-processing"),
    );
  }
}
