import { Controller } from "stimulus";

const groupOpenClass = "group-open";
const groupClosedClass = "group-closed";

export default class extends Controller {
  static targets = ["trigger", "blockChildren", "triangle"];

  connect() {
    if (
      this.element.dataset.communityMember == "true" &&
      this.blockChildrenTarget.childElementCount > 0
    ) {
      if (this.element.dataset.collapsed == "true") {
        this.triangleTarget.classList.remove(groupOpenClass);
        this.triangleTarget.classList.add(groupClosedClass);
      } else {
        this.triangleTarget.classList.add(groupOpenClass);
        this.triangleTarget.classList.remove(groupClosedClass);
      }
    } else {
      this.triangleTarget.classList.remove(groupOpenClass);
      this.triangleTarget.classList.add(groupClosedClass);
    }
  }

  toggleChildrenVisibility(ev) {
    ev.preventDefault();

    if (
      this.blockChildrenTarget.childElementCount > 0 &&
      this.element.dataset.communityMember == "true"
    ) {
      this.blockChildrenTarget.classList.toggle("hidden");
      if (this.blockChildrenTarget.classList.contains("hidden")) {
        this.triangleTarget.classList.remove(groupOpenClass);
        this.triangleTarget.classList.add(groupClosedClass);
      } else {
        this.triangleTarget.classList.add(groupOpenClass);
        this.triangleTarget.classList.remove(groupClosedClass);
      }

      this.postToggleEndpoint();
    }
  }

  postToggleEndpoint() {
    fetch(this.element.dataset.collapsibleUrl, {
      body: JSON.stringify(),
      method: "POST",
      dataType: "script",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }
}
