import debounce from "lodash.debounce";
import { Controller } from "stimulus";

const DEBOUNCE_INTERVAL = 50;
const FOOTER_DISTANCE_FROM_BOTTOM = -1000;

export default class extends Controller {
  static targets = ["footer", "loader", "button", "content"];
  initialize() {
    this.page = 2;
    this.url = new URL(this.element.dataset.url);
  }

  connect() {
    this.debouncedScrollHandler = debounce(() => {
      if (this.loading) {
        return;
      }

      if (this.hasFooterTarget) {
        const position = this.footerTarget.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const iOSDevice =
          /iPad|iPhone|iPod/.test(navigator.platform) ||
          (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

        if (
          windowHeight - position.top > FOOTER_DISTANCE_FROM_BOTTOM &&
          !iOSDevice
        ) {
          this.fetchNewPage();
        }
      }
    }, DEBOUNCE_INTERVAL);

    this.debouncedScrollHandler();

    document.addEventListener("turbolinks:load", this.debouncedScrollHandler);
    document.addEventListener("turbolinks:click", this.debouncedScrollHandler);
    window.addEventListener("scroll", this.debouncedScrollHandler, {
      passive: true,
    });
  }

  disconnect() {
    if (this.hasFooterTarget) {
      window.removeEventListener("scroll", this.debouncedScrollHandler);
      document.removeEventListener(
        "turbolinks:load",
        this.debouncedScrollHandler,
      );
      document.removeEventListener(
        "turbolinks:click",
        this.debouncedScrollHandler,
      );
      this.footerTarget.classList.add("invisible");
    }
  }

  loadMore(event) {
    event.preventDefault();
    this.fetchNewPage();
  }

  getUrl() {
    this.url.searchParams.set("page", this.page);
    return this.url.href;
  }

  setLoadingState() {
    this.loading = true;
    this.buttonTarget.classList.add("invisible");
    this.loaderTarget.classList.remove("invisible");
  }

  resetLoadingState() {
    this.loading = false;
    this.buttonTarget.classList.remove("invisible");
    this.loaderTarget.classList.add("invisible");
  }

  fetchNewPage() {
    if (this.loading || this.element.dataset.lastPageReached) {
      return;
    }
    this.setLoadingState();
    fetch(this.getUrl())
      .then(res => res.json())
      .then(({ content, hasNextPage }) => {
        if (this.hasContentTarget) {
          this.contentTarget.insertAdjacentHTML("beforeend", content);
        } else {
          this.footerTarget.insertAdjacentHTML("beforebegin", content);
        }
        this.page++;
        if (hasNextPage) {
          this.resetLoadingState();
        } else {
          this.element.dataset.lastPageReached = true;
          this.disconnect();
        }
      });
  }
}
