import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  goBack(ev) {
    ev.preventDefault();
    const fallbackUrl = this.element.dataset.fallbackUrl || "/";
    const prevPage = window.location.href;
    window.location.href = fallbackUrl;

    setTimeout(function () {
      if (window.location.href == prevPage) {
        window.location.href = fallbackUrl;
      }
    }, 500);
  }
}
