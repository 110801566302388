import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source"];

  copy(event) {
    event.preventDefault();
    this.sourceTarget.select();
    document.execCommand("copy");
  }

  copyText() {
    try {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = this.element.dataset.source;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    } catch (error) {
      console.error("ClipboardController - Failed to copy text:", error);
    }
  }

  copyWithPrefix() {
    try {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value =
        (this.element.dataset.prefix ? this.element.dataset.prefix : "") +
        this.sourceTarget.value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.sourceTarget.select();
      event.preventDefault();
    } catch (error) {
      console.error("ClipboardController - Failed to copy text:", error);
    }
  }
}
