import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["card", "error", "form"];
  isSubmitting = false;
  connect() {
    const stripeMeta = document.querySelector('meta[name="stripe-key"]');
    if (stripeMeta === null) {
      return;
    }

    const stripeKey = stripeMeta.getAttribute("content");
    this.stripe = Stripe(stripeKey);
    const elements = this.stripe.elements();

    this.card = elements.create("card");
    this.card.mount(this.cardTarget);
    this.card.addEventListener("change", this.changed.bind(this));
  }

  setSubmitting = value => {
    this.isSubmitting = value;
  };

  changed(event) {
    if (event.error) {
      this.errorTarget.textContent = event.error.message;
    } else {
      this.errorTarget.textContent = "";
    }
  }

  async submit(event) {
    if (this.isSubmitting) {
      return;
    }
    this.setSubmitting(true);
    event.preventDefault();
    await this.stripe.createToken(this.card).then(result => {
      if (result.error) {
        this.errorTarget.textContent = result.error.message;
      } else {
        this.handleToken(result.token);
      }
    });
    this.setSubmitting(false);
  }

  handleToken(token) {
    this.addHiddenField("community[processor]", "stripe");
    this.addHiddenField("community[card_token]", token.id);

    Rails.fire(this.formTarget, "submit");
  }

  addHiddenField(name, value) {
    const hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", name);
    hiddenInput.setAttribute("value", value);
    this.formTarget.appendChild(hiddenInput);
  }
}
