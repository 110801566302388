// app/javascript/packs/controllers/mobile_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.isOpenClass = "is-open";
    this.sidebar = document.querySelector("[data-target='nav']");
  }

  toggleNav(ev) {
    ev.preventDefault();

    if (this.sidebar.classList.contains(this.isOpenClass)) {
      this.closeNav();
    } else {
      this.openNav();
    }
  }

  openNav() {
    this.sidebar.classList.add(this.isOpenClass);
    document.body.classList.add(this.isOpenClass);

    document
      .querySelector(".mobile-overlay")
      .addEventListener("click", this.closeNav);
  }

  closeNav() {
    document.querySelector("[data-target='nav']").classList.remove("is-open");
    document.body.classList.remove("is-open");
    document
      .querySelector(".mobile-overlay")
      .removeEventListener("click", this.closeNav);
  }
}
