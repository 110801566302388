import { Controller } from "stimulus";
import { fetchRequest } from "../react/helpers/backendRequestHelpers";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    this.toggleClass = this.data.get("class") || "hidden";
  }

  toggle() {
    this.menuTarget.classList.toggle(this.toggleClass);
  }

  show() {
    this.menuTarget.classList.remove(this.toggleClass);
  }

  hide(event) {
    if (
      this.element.contains(event.target) === false &&
      !this.menuTarget.classList.contains(this.toggleClass)
    ) {
      this.menuTarget.classList.add(this.toggleClass);
    }
  }

  hideModal(event) {
    const isClickInside = this.menuTarget.firstElementChild.contains(
      event.target,
    );

    if (!isClickInside) {
      this.menuTarget.classList.add(this.toggleClass);
    }
  }

  optionSelect(event) {
    const body = {
      [this.element.dataset.fieldName]: event.target.dataset.sort,
    };

    fetchRequest(this.element.dataset.onOptionChangeUrl, {
      method: "PATCH",
      body: JSON.stringify(body),
    }).then(() => {
      window.location = this.element.dataset.onSuccessUrl;
    });
  }
}
