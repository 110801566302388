import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "inAppNotificationSetting",
    "emailNotificationType",
    "mobileNotificationSetting",
    "toggleInAppNotifications",
    "toggleEmailNotifications",
    "toggleMobileNotifications",
  ];

  setAllInAppNotificationSetting(event) {
    const { value } = event.target;
    this.inAppNotificationSettingTargets.map(target => {
      if (value != "") {
        target.value = value;
      }
    });
  }

  setAllMobileNotificationSetting(event) {
    const { value } = event.target;
    this.mobileNotificationSettingTargets.map(target => {
      if (value != "") {
        target.value = value;
      }
    });
  }

  setAllEmailNotificationType(event) {
    const { value } = event.target;
    this.emailNotificationTypeTargets.map(target => {
      if (value != "") {
        target.value = value;
      }
    });
  }

  setAllToggleEmailNotifications(event) {
    const checkedValue = event.target.checked;
    this.toggleEmailNotificationsTargets.map(target => {
      if (target.checked != checkedValue) {
        target.checked = checkedValue;
      }
    });
  }

  setAllToggleInAppNotifications(event) {
    const checkedValue = event.target.checked;
    this.toggleInAppNotificationsTargets.map(target => {
      if (target.checked != checkedValue) {
        target.checked = checkedValue;
      }
    });
  }

  setAllToggleMobileNotifications(event) {
    const checkedValue = event.target.checked;
    this.toggleMobileNotificationsTargets.map(target => {
      if (target.checked != checkedValue) {
        target.checked = checkedValue;
      }
    });
  }
}
