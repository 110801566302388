import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    // The class we should toggle on the container
    this.toggleClass = "hidden";
    // The HTML for the background element
    this.backgroundHtml = this._backgroundHTML();
    // The ID of the background to hide/remove
    this.backgroundId = "modal-background";
    this.modalContainerId = "modal-container-";
    this.modalInnerHtml = this.containerTarget.innerHTML;
    this.containerTarget.innerHTML = "";
    // Let the user close the modal by clicking on the background
    this.allowBackgroundClose =
      (this.data.get("allowBackgroundClose") || "true") === "true";

    if (this.element.dataset.open) {
      this.open();
    }

    window.addEventListener("click", this.closeBackground);
  }

  disconnect() {
    window.removeEventListener("click", this.closeBackground);
  }

  open = e => {
    if (e) {
      e.preventDefault();
    }

    // Lock the scroll and save current scroll position
    this.lockScroll();

    // Unhide the modal
    // this.containerTarget.classList.remove(this.toggleClass);
    // this.containerTarget()

    // Insert the background
    document.body.insertAdjacentHTML("beforeend", this._modalContentHTML());
    this.modal = document.querySelector(`#${this.modalContainerId}`);
    this.modal
      .querySelectorAll("[data-action='click->popup#close']")
      .forEach(element => {
        element.addEventListener("click", this.close);
      });
    if (!this.data.get("disable-backdrop")) {
      document.body.insertAdjacentHTML("beforeend", this.backgroundHtml);
      this.background = document.querySelector(`#${this.backgroundId}`);
    }
  };

  closeBackground = event => {
    if (!this.modal) {
      return;
    }

    const modalInner = document.querySelector(".modal__inner");
    if (!modalInner) {
      return;
    }
    if (
      this.allowBackgroundClose &&
      this.modal.contains(event.target) && // Prevent close on clicks outside modal container (includes overlay)
      !modalInner.contains(event.target) && // Prevent close when clicked inside modal
      !this.element.contains(event.target) // Prevent close when user clicks on the trigger
    ) {
      this.close(event);
    }
  };

  close = e => {
    if (!this.background && !this.modal) {
      return;
    }
    e.preventDefault();

    // Unlock the scroll and restore previous scroll position
    this.unlockScroll();

    // Hide the modal
    // this.containerTarget.classList.add(this.toggleClass);

    // Remove the background
    if (this.background) {
      this.background.remove();
    }
    if (this.modal) {
      this.modal.remove();
    }
  };

  closeWithKeyboard = e => {
    if (
      e.keyCode === 27 &&
      !this.containerTarget.classList.contains(this.toggleClass)
    ) {
      this.close(e);
    }
  };

  _backgroundHTML() {
    return '<div id="modal-background" class="modal-background"></div>';
  }

  _modalContentHTML() {
    return `<div id="${this.modalContainerId}" class="modal-container">${this.modalInnerHtml}</div>`;
  }

  lockScroll = () => {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Save the scroll position
    this.saveScrollPosition();

    // Add classes to body to fix its position
    document.body.classList.add("fixed", "inset-x-0", "overflow-hidden");

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`;
  };

  unlockScroll = () => {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null;

    // Remove classes from body to unfix position
    document.body.classList.remove("fixed", "inset-x-0", "overflow-hidden");

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition();

    // Remove the negative top inline style from body
    document.body.style.top = null;
  };

  saveScrollPosition = () => {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  };

  restoreScrollPosition = () => {
    document.documentElement.scrollTop = this.scrollPosition;
  };
}
