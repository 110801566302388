import flatpickr from "flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import { Controller } from "stimulus";
import { getFormattedDate, lastDayOfMonth } from "../src/helpers/dateHelper";

export default class extends Controller {
  static targets = ["monthInputRangeTo", "monthInputRangeFrom"];

  connect() {
    const pickrConfig = {
      mode: "range",
      dateFormat: "j M Y",
      maxDate: "today",
      plugins: [
        new monthSelectPlugin({
          shorthand: true, //defaults to false
          dateFormat: "M Y", //defaults to "F Y"
          altFormat: "F Y", //defaults to "F Y"
        }),
      ],
    };

    flatpickr(this.monthInputRangeToTarget, {
      ...pickrConfig,
      onClose: function (selectedDates) {
        if ("URLSearchParams" in window) {
          const lastDate = lastDayOfMonth(selectedDates[0]);

          const minDate = getFormattedDate(
            new Date(this.monthInputRangeFromTarget.value),
          ).replace(/\//g, "-");
          const maxDate = getFormattedDate(lastDate).replace(/\//g, "-");
          this.setURLParams(this.monthInputRangeToTarget, minDate, maxDate);
        }
      }.bind(this),
    });

    flatpickr(this.monthInputRangeFromTarget, {
      ...pickrConfig,
      onClose: function (selectedDates) {
        if ("URLSearchParams" in window) {
          const lastDate = lastDayOfMonth(
            new Date(this.monthInputRangeToTarget.value),
          );

          const minDate = getFormattedDate(selectedDates[0]).replace(
            /\//g,
            "-",
          );
          const maxDate = getFormattedDate(lastDate).replace(/\//g, "-");
          this.setURLParams(this.monthInputRangeFromTarget, minDate, maxDate);
        }
      }.bind(this),
    });
  }

  setURLParams(element, minDate, maxDate) {
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set(element.dataset.minDateParam, minDate);
    searchParams.set(element.dataset.maxDateParam, maxDate);
    window.location.search = searchParams.toString();
  }
}
