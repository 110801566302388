import { Controller } from "stimulus";

export default class extends Controller {
  get memberPublicUid() {
    return this.data.get("member-publicuid");
  }

  openMemberProfileEdit() {
    const memberPublicUid = this.memberPublicUid;
    this.element.dispatchEvent(
      new CustomEvent("open-member-profile-edit", {
        detail: { memberPublicUid },
        bubbles: true,
      }),
    );
  }
}
