import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["title", "description", "buttonLabel", "buttonUrl"];

  connect() {
    this.updateModal();
  }

  updateModal() {
    if (this.titleTarget.value != "") {
      document.getElementsByClassName("modal__title")[0].innerHTML =
        this.replaceCustomStrings(this.titleTarget.value);
    }

    if (this.descriptionTarget.innerHTML.length != "") {
      document.querySelectorAll(
        ".modal__description .trix-content",
      )[0].innerHTML = this.replaceCustomStrings(
        this.descriptionTarget.innerHTML,
      );
    }

    if (this.buttonLabelTarget.value.length != "") {
      document.getElementsByClassName("modal__button-label")[0].innerHTML =
        this.buttonLabelTarget.value;
    }

    if (this.buttonUrlTarget.value.length != "") {
      document.getElementsByClassName("modal__button-label")[0].href =
        this.buttonUrlTarget.value;
    }
  }

  replaceCustomStrings(body) {
    body = body
      .replace("{first_name}", this.element.dataset.userFirstName)
      .replace("{community_name}", this.element.dataset.communityName);
    return body;
  }
}
