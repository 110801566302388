import Slideout from "slideout";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu", "panel"];
  connect() {
    this.slideout = new Slideout({
      touch: false,
      panel: this.panelTarget,
      menu: this.menuTarget,
      padding: window.outerWidth - 50,
      tolerance: 70,
    });
    // Need for accessing from React router
    window.mobileDrawer = this.slideout;
    this.menuZIndex = this.menuTarget.style.zIndex;

    const close = event => {
      event && event.preventDefault();
      this.slideout.close();
    };

    this.slideout
      .on("beforeopen", function () {
        this.panel.classList.add("panel-open");
      })
      .on("open", function () {
        this.panel.addEventListener("click", close);
      })
      .on("beforeclose", function () {
        this.panel.classList.remove("panel-open");
      })
      .on("close", function () {
        this.panel.removeEventListener("click", close);
      });
  }

  disconnect() {
    this.slideout.destroy();
  }

  onOverlayOpen(event) {
    event.preventDefault();
    this.menuTarget.style.zIndex = 1000;
  }

  onOverlayClose(event) {
    event.preventDefault();
    this.menuTarget.style.zIndex = this.menuZIndex;
    this.close();
  }

  close(event) {
    event && event.preventDefault();
    this.slideout.close();
  }

  toggle(event) {
    event.preventDefault();
    this.slideout.toggle();
  }

  open(event) {
    event.preventDefault();
    this.slideout.open();
  }
}
