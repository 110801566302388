import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["spaceMemberSelect", "spaceGroupMemberSelect"];

  toggleSpaceGroupMemberSelect() {
    this.spaceGroupMemberSelectTarget.classList.toggle("disable-element");
  }

  toggleSpaceMemberSelect() {
    this.spaceMemberSelectTarget.classList.toggle("disable-element");
  }
}
