import scrollIntoView from "scroll-into-view-if-needed";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["link"];

  connect() {
    this.linkTargets.forEach(linkTarget => {
      if (
        document.location.href.startsWith(`${linkTarget.href}/`) ||
        document.location.href === linkTarget.href
      ) {
        linkTarget.classList.add("nav-link--active");
        linkTarget.classList.remove("nav-link--inactive");
        scrollIntoView(linkTarget, {
          scrollMode: "if-needed",
          block: "center",
          inline: "center",
        });
      } else {
        linkTarget.classList.remove("nav-link--active");
        linkTarget.classList.add("nav-link--inactive");
      }
    });
  }
}
